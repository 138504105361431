import Storage from "@/utils/storage";
import axios from "axios";
import { Toast } from "vant";
import Vue from "vue";

const qs = require("qs");

Vue.use(Toast);

const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API,
  timeout: 15000,
  paramsSerializer: (params) => qs.stringify(params, { arrayFormat: "repeat" }),
});

service.interceptors.request.use(
  (config) => {
    let { loading } = config.headers;
    const accessToken = Storage.getItem("token");

    if (loading !== false) {
      config.loading = Toast.loading({
        mask: true,
        duration: "0",
        forbidClick: true,
      });
    }
    if (accessToken && config.headers.needToken) {
      config.headers["Authorization"] = accessToken;
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

service.interceptors.response.use(
  async (response) => {
    await closeLoading(response);
    const code = response.data.code || 200;
    if (code === 401) {
      Toast("请先登录");
    } else if (code !== 200) {
      if (response.data.msg == "insertExisit") {
        return response.data;
      } else {
        Toast(response.data.msg);
      }
    } else {
      return response.data;
    }
  },
  async (error) => {
    await closeLoading(error);
    return Promise.reject(error);
  }
);

export default function request(options) {
  return new Promise((resolve, reject) => {
    service(options).then(resolve).catch(reject);
  });
}

const closeLoading = (target) => {
  if (!target.config || !target.config.loading) return true;
  return new Promise((resolve) => {
    setTimeout(() => {
      Toast.clear();
      resolve();
    }, 200);
  });
};
